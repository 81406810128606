import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#575757', // Your button background color
      contrastText: '#ffffff', // Button text color
    },
    secondary: {
      main: '#333', // Another primary color in your app
    },
    success: {
      main: '#98FB98', // Light green for validated steps
    },
    text: {
      primary: '#f5f5dc', // Text color for input
    }
  },
  typography: {
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          border: '2px solid #f5f5dc', // Button border color
          fontWeight: 600,
          fontSize: '20px',
          marginTop: '20px',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            color: '#98FB98', // Light green for completed steps
          },
          '&.Mui-active': {
            color: '#E0AA3E', // Light green for active step
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: '#ffffff', // Light green for step labels
          '&.Mui-completed': {
            color: '#ffffff', // Light green for completed steps
          },
          '&.Mui-active': {
            color: '#E0AA3E', // Light green for active step
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            color: '#f5f5dc', // Text color for input
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#f5f5dc', // Border color for input
              borderRadius: '20px', // Border radius for input
            },
            '&:hover fieldset': {
              borderColor: '#f5f5dc',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#f5f5dc',
            },
          },
          marginTop: '30px',
        },
      },
    },
  },
});

export default theme;
