export const stakers = [
    "0xD819271C507352524A0D3bC26C7b7f2afb3Cf8AA",
"0xa623F1e46bF7b1DEC8EDeb27960768c1e44218E4",
"0xb5E31fb212906EB7b3f9fE485736e84E7f4e69D1",
"0x1eA9A0012156e42d76B54e3b2E1175F9aBc77d57",
"0x61e989aec7EE6f1355413cBCc69FA4105bDF8d89",
"0x258b320A7B9660018aaf20492736E40a369057f8",
"0xe98bAb964907222Ee25C070F3E57a4aa59635730",
"0xA05ca087c5fe420252Fa5F71F68E956438C37632",
"0x8db88Cb0658D072A5044D885C2fC8391b0d10a5C",
"0xa173325CE21827B22F4e573CE9990D639e5D7e0f",
"0xD637BE6508296417C7906281E44d544d14576DEB",
"0x7F37Cb4E1fF71193175B11c16FaA7d97aa851876",
"0x2Fd4306ca72c3119D3e26A237b1E95419976461E",
"0xa7cBba0eE90AEc15B7CdA2409F8912ED00c7EdD2",
"0x8A303Db364448FeFb91E711390102b3dbAaC341d",
"0x6db03eAe1f494ADefeF94D8f3506547C3EB80261",
"0xbe8aCe454E5654123E2b9609b8D186B3FB6C0A13",
"0x92C017f6729273558E73CDDc8EB6A0201ae03EB5",
"0xc1692cD69493436b01cddcbE5FeDbC911746A7C1",
"0xA976A66BfcBd5d71E6d0B7A0A3A9AA8EAa1b377a",
"0x83FeA1ed777A247E658AebeD19bEB496226629f8",
"0x20a874282B7d97F4fd1b58c0A831964D321Eae12",
"0x3FD986EA4767cDb9f48C14e469c1cdB9f1e56e56",
"0x01810115e4e1906032eD35507109C6914684eC75",
"0xcf46AD13b266F57c5683a1db4fbEFf4f915ef2b2",
"0xDa6C8F0583a0671B04021836433b67919e149B26",
"0x9D41bECeeBf3E441713C34A483465aB1D57e9CB1",
"0xFA808Ac8F7C239880E50514A5Effd0245b699A2c",
"0xDCe047654e9485c025d9ac9D906eBa322BC8C948",
"0x02e903FEA789f51630BAE4d1e4ADEFDed767D8d0",
"0xD6439Ebe4bc98af0c89f12e715001182777a377D",
"0x7f2E86516ca69a536CF79662BACa73371c529e6a",
"0x1Ce363aabB946e4400564A09D2d11064Ae15a92d",
"0x37b0C67729ad92E3e181E7FB7CD396B4F93dB1b2",
"0xDd69c8B49873D6b9Bf0E4fd90A5bfCb819Cc2456",
"0xE3D94a3B9DA2cC5864a2cCC0a744F97194890f08",
"0x4D6d40766110E41Cb4b0834295E1D65C3dD991C3",
"0xF8dD95FBB34eeA1f1065cC8C01DE0235Eec1d22B",
"0xAa15a96FBA6c84737e632a93140D9F549F55338F",
"0xbd18c9ac0b4d2Bd841065aD7B920C4Be49c5DC71",
"0xAc72869BeB068E777d85755C8eFAeBf50cAF4413",
"0x40cf1e71B3FfeEbcd2Cfa854655586B875E80771",
"0xF6cDfb166F533bB08735C78F92592725D17D92f3",
"0x9CFB3D2d5a4c745f79D4B79Fcb2832E0698B7CD6",
"0xB7e9722F6B77EBd6582EF54d410ef7227DD6a7d4",
"0xe7671F8B81CbEA41eDF97A414DB712111744da5A",
"0x37a742a86F6260F5ee5953eEC554FEd40F57DB17",
"0xE6DCC47f585896C40df9384574BFD1f2Ae31C49F",
"0xbF4EB258a99c155129cAbF7eAeC1A92a3E0DBa0E",
"0x191e03b2c1caE6Bb31900e6345025C05AE9987FB",
"0xD108CB5765a0aBC006CFE77D931113e65C92b611",
"0xE291b382125e97101E73De3CF2606e4a657aD958",
"0xa15AAF9B9a85652CC7CD7fdA9f85e6404B806a50",
"0xFC72474901203fC89326Ace57dBb089A36E7AfD9",
"0x3725007C2c7186F1c8e5733Ee9A6C58d3eC2159D",
"0x24bc2bE26704986B1B2c29eb45d58b2b9F1B1567",
"0xe00cfEF185D6e06b0437B476eb69A5EAd9a983B0",
"0x751728c00B0F671BC2b6A1Dd60FdBb76AdfE8D99",
"0x8C72e43cEdF0b58cC654db9b3CE4E035b6ecCa64",
"0xB3849c83a62CED2bdc140856444d393f0c5F831B",
"0x577916bFc93a80c0f8A3aCaBFd928F29446b0761",
"0x7D958Ba0FB91c5eaE3F72AB7D540e40BD32A826E",
"0x6c9382F8Acc564968e4E4ff47762D02357350B42",
"0x8422F2b934e30B2b4f1547Cef4d6733d4F50Baf9",
"0x046659bB263FD831e3e1383E7AcB6c37B622b29f",
"0xc7f81aAC4020648Ad90eE5a3eDEfE858e3079488",
"0xB1C1712B394697EcbD7d3bAe11ED8426b0B3e4B6",
"0xF06f292bC7231DFa2ABe0c874e3f1309a30C7A62",
"0x4362BBa5a26b07db048Bc2603f843E21Ac22D75E",
"0xA58ADADa9E5090f7D66417747ABA9E3f579e4625",
"0xf77c2Edf54A438C83CF10Efa9a74847040b17baA",
"0x2144afbDAc8B4e1eD184b7Bd34D4D831fCa482Da",
"0x326034FfDEae40Ba7E1C4D3014abe0f4741cB651",
"0x770A3e77249A23919875feD6cd795e9C2706a368",
"0x50e1A6F31fB3537BB96c26E09005c08BbD5EA03c",
"0x0eb29E34F4b16b63cA7FBa69A2E352Ca5Cc3193C",
"0xcF0722F0F8d164529A1aAA4280057103DAea8007",
"0xCBf5dd87ED51867a45465EF618BCa58C21D586D5",
"0xaB493F526cB26d1915A99fAB76EDd720Dd3E8583",
"0x3967E38C11334b6ecb649315615cF05e7f9EBd49",
"0x7d2Bc45eF2B68C52E6e6ee15264bB3e4BEDC4880",
"0x9844fBB93Dd5Cd0d19818b9b7ccEa99b5a777B1D",
"0xFD15Fd0B66E950b82cDD8ac3F4BDcEdeD58a7012",
"0x465945Fa5D23C9437D22F1A211f387A527B09b49",
"0x94e7786102295eb231a5E53aaf05758C43E7904d",
"0xd40944D5284459cDd871570BA0bB98687A79560b",
"0xBA5184F51D11dEBfdaBB732aEdF43B76eB9A9bC6",
"0x10B35bB02F5c5ee806F36A1908f1F0f6e4576700",
"0x6923D236E448b23d56438386D50F2CB63584faeF",
"0x4eB7Fa65224362A35Af6F1eED0f9832870520843",
"0x1D7a70F4e49CabAFe361Df071a0E76730ACE0ecF",
"0x1B78A3C3ABdA8Fceb15e83ece78Cf3232A38b65a",
"0x3161dcB17c7beAF05f23CdD6B8655Ef6e9d4D26c",
"0x27a5dfB8eCA8bFcC6B0725c48c233764dF858Dab",
"0x46F2d8131950759Cc11B3834e6D7018fBf1375E9",
"0xBDBc5A48dAc64b3218EE1B2Fe04Fd4482A4738D3",
"0x898617436E7Ebbc6F06a7035a6c49Ff1B0d2d579",
"0x675AB0bCf7abCE589a3017cA3886f01A3536F663",
"0x52CeBe03Be71f15F3b3FC3dEf5dD2c6Fe3017639",
"0x08ffeD0b99Db9fa80128d7f89424AE44DB1a7331",
"0xca51b8E4F5C216999C6222F304c4a02ce78086F9",
"0xfadc584b486F24Bec918f48572C0c95e91C6eEa1",
"0xea79b1f6EA30a2DccB7C066Da6204fBf4131BD2C",
"0x418fD25daa74B16F0a574cacb91F4BBaaFa934D6",
"0x5983aC87493Ba49aC853735F294b21e4B887Fa6F",
"0xF9d9E28244e0C9590Fe2453d47Eb430BBD0fB362",
"0x3076dD2c4f6797034Ffb11cedFca352b579b120E",
"0x69b593E4395BF6da3429B878467a550a8aF87FA3",
"0x4E0B603649ceE1dECC60Ee7a8EA3C051d67a41fF",
"0x11B32aAb7237eeD04D3649E11f94eaDd2E2BC039",
"0x863fC7eb6eeB0d0c41fC8E19C3e6bA55355327E5",
"0xee0E1ff3F2A88150B0dA01BB473B4766d34ceBFB",
"0xd3119ac49ed87c594A521F9930be6d0C6B2cBC71",
"0x57919ADE0640D36E53e0d3e18aaD93bd990d764a",
"0x4D720E5E82f35a53050E9009Bb8080c978f7fE69",
"0xFB52336Fc04588bC542bBB87E1c53Ab19135D482",
"0xaBF0bDb22bd614AaD8f5200E7E872D7Ea0B5e2Ff",
"0xDB5b4853ceaBf6D8e08D73FddFa80F507Ef9Fba4",
"0x9038CCF038fB5453943F6d686F50B9364EA3a735",
"0xB43c7734A02C243c138E63B5E322d382E6Cd826a",
"0xA71061DAB2228346D89cCcC11BDa8629cE898251",
"0x72Aa2e446A97Fde6EF5D2B137D4605584C7C2dbd",
"0x70482fEE42Bf679533f3B9Dd64eAb9dCca300931",
"0xd4428C81D495154780f6a1307Cf47D1176F783E8",
"0x4d9e27AD0FDF2DA232d81DC8d61Ce2B004A5a96D",
"0xB32Ec9935f9131D7F3D0B3AD1C28cA60f828a395",
"0xe56dF56feD944B578D81faf5799B8dA6E9DBE351",
"0xB9020b1F7a0eFf9e21008472E4E2186EA12FD039",
"0xF5C00b2a43a35324269b8d5D2A3FA1FedD98EB26",
"0x88a5eC7Bc54612DA6C722528e6BB07045Ed58EB3",
"0x472f03101932a46fbB7b619c2C39697427EF728b",
"0x483172972f3265a576ca76A59953974e8DeFE2d8",
"0xBf7622f8bd1Ed0aFDe92e53dFEd9F724326207d2",
"0x2eAc7B9628ABC7B917e8DFc26Bf65e25ea6B2734",
"0x6eBB4411f04CEBb84Ef61A3941ED8f62AC3cDb3d",
"0x6dAAD986c07338508FF522F8fF7B8678735aDd78",
"0x0d76Ae1322e68e48C3B8094bBa7eC821c5e5cEe2",
"0x080521b3a15058FeDFa60cee92642625CE543428",
"0xA0620c550E4c65E8aBE375DCaAf8cD2A342238a8",
"0x4a37AaE052bCB11E30F718e1C9164EF881aC3434",
"0xE756abfd5598a2Fccd599e11832D30C4965B1145",
"0xBf76bB79A69Dc397Fe60Bf9Ad49A14EB9CeE75C8",
"0x098F1925660FaFF87AA134b15B4032f484CBc079",
"0xfDc2B224cc1fd65d6cdCcDd896510A7d89aF81c3",
"0x9EE95e5B1419A347a0A82EBB4CD4E9DD5065A77a",
"0x695B82186F2897b91593F744943392B3043b32ae",
"0x5754716de129492C43Bccd8f230e5Af21Cc2fB8a",
"0xC6F5c5Fa1370Dc0Ad67832923b1E7c76bC86678B",
"0x16bc0BF4934f24D3c35CfC81097afb64AB99fB81",
"0xA270155e5f263d111a2166591Cda5526c1d44cB1",
"0xb15A56Cc287555C44b391Ecd561247791a138ef4",
"0x6F9900f5dF40399080a0CC206AD41c674CFa66CE",
"0xa8d061a149a33CAfacB20A210cE601417bb7c06D",
"0x61C611B005e9e6c8e321DC73013dBA0dB3E37552",
"0x6D778FdFBfB9Bc8a55c8fd98529bCc831Fd8Ba54",
"0x02e8935b5E4A6E532AA8DDB2C77b21692FaDd078",
"0x8Af7B17787b38afF12e632fD7800f979fd75218A",
"0xBD26C5a709f629206a22aA5471cA863960E91523",
"0xfB7BDA0d2965831d3792bA8d3f82cc75458A87bF",
"0xEb94D605069EC5577ffFAa0988772aFbA38641c1",
"0x9C1e57C15927ffF0048de2B44691acb372762c2c",
"0x3a9c20eA3FE9e68A78e40ce862D7D14AaC2C69BD",
"0xa9bD4B958b83C94362441d7297Cb461976BF4ECA",
"0x4c43E3AA5BC37783b73376f14CF749cB762e09E1",
"0x9C19a3CE20Fe385ac79dE60a7783E39b572d52AF",
"0xb364Ea270E57d1e573fb1d8799e8408192189634",
"0x5f33e67Fe8b82DFffee2C0D5405621dee323146E",
"0x8BBfF56655a6E65E51C94BcfF1fbCC0AFB35c7FB",
"0x7827ff8c343d162658bc55A949bC676A988Af3C5",
"0xb1824cAEe00bAA6FE8F96b99b5b08d854429DC82",
"0x0De8c9E3F225deB2B93f0BFaCf643bF4B8afa69F",
"0x93FDae215370bB6872AbD303F4A0dE0d4b6955f6",
"0x490d2D91bb5bAC5Ace187bc7e8667aE22094F4a7",
"0x1d9C0aE5d4959f92C32e28a82b53D2cF8FDcEBD1",
"0xEee3a90f0BF266556fF1aF9120A5a78e0229521F",
"0x4cF82c0D118543de49cf3b3d99d81050Cf4F886A",
"0xb5a80275ddcACa8ce41fB0476289FCDfE6bB31D7",
"0xc14a64c90E3170EeB8F5998d466698B935E5F333",
"0x527dDa827f0D0B140fD4294Bc2F0B1918FC805bD",
"0xa86F62A6E02A22D155972C46560971B585e947f7",
"0x11436EE79814f04b71594D651E8A23D16f0eA519",
"0x0FB9221ce9A651e9C74c6f96358D1E6DE8c1A064",
"0x624E280Df19C74dC32C0bb21505Aa1323F00b550",
"0x1E5E68893e60Ae6076e74dAf471eA817f465671f",
"0x65be086E4AcB16D5aA4A8Ea849C0bE2aD86e890B",
"0xB6Dc8d3BD31CEd5fE3b6a9726E2e4F37b559EaE2",
"0xC78d06A4553F520C861A9a3C380ec25586D1F9FD",
"0x06b4eC4CD1158a12ae3604ed2194B60ebe22B66a",
"0xc477f5492D7dc9616b6a7140B7f8A8BE828C67A0",
"0x6d243695cBA854b3BF01A2DbfCE92f1fA04Aee58",
"0x661219126f8a5D7A52AE87545DC200DA3ee68Ff6",
"0x453bc3AcddF2E75739bbe4aDc16C14c67B9368FA",
"0x92297c950Cb1Ec7B91f836671Cfea6Ec770d377d",
"0xdaBe3ba4aa33251076405147D088ff16a038B0Be",
"0xE4Cd7db1E3af410C937f421FcE0BA78B15999AB5",
"0xb673f378D7DEa5AcFFDbc5837D82F3B0CD087323",
"0x80F1344A4960Eb8Bb25024EA936C7195Bf2Dd26D",
"0x1214cfaF2E6f449F993F6f416C8f745D37DcE1FC",
"0x848F11E9c468be9EF6bF5F1Daa742e6ADF25D7A7",
"0x3A6305f77623b3EbF8373421d690CEb2BC816F1a",
"0x1e0C2e3F0e470460099353c3C462d661bb6a2b26",
"0x410470cc8708D98ab91Abf082C5B76Ab346F085B",
"0xfa8B48D9a5007a530ab20C8d35598e530b4A6952",
"0xf0D3cC63e7AacC9bFaf477D4239a3Ece3cfd4625",
"0x20baC979496F1468F4e2E82686185ecD289e3Fc5",
"0x938E2063f460C53DF49367Db028a69Ac6e4AAea3",
"0xb735da2315E2fe33ee7d43D2BDDDB9aAc492abB0",
"0x77Ec2ca5808AeCcD588688E57fD9E47cC95457f2",
"0x687491ac3d9f32e338A5Eaf7688a525C7BffB656",
"0x10f46b32574F948a9F5a1c9020b29E2D4A16715C",
"0xe038172d3F449350f03a67CcA32FeDAa79a1683e",
"0x3E4C0e5136C5264c210cE6aa889854d0b8380CA1",
"0xd8CaDfD2fBb21916Be26bf24c05e41a826c53849",
"0x88135266d169A83BFccb9C3d033f71Ed0E98ec28",
"0x05bf19079f1CB0CFf34683876003F23AF9fC65b8",
"0x499941bd3Cd1b3d88F14eaFc83b9Ad02e212a60b",
"0x5054F0C835943bE5dc9c55e7D4A282154eba0028",
"0xd3C0927C93195C7E723E1677c4FfC977e36e7C0e",
"0x7c99794f7E145E006bE694EdE8F3C8c64E87F820",
"0x6972AB424477AC48C36352ACc7c4694Cb9451f62",
"0xaeec91ED89433D4223Ca83AA0B1893519BdDE06a",
"0x2c965F6008D0C4f366edd7D6CEB5eB49641c962b",
"0x8A7d53e9Fb9230C47e40771113460A34Ec123e7D",
"0x35D587d4fd525e37d38C23a6BcCB1c4D2531400E",
"0x4dc4C46Fc1842f45113aE473809D2e88d8396d10",
"0x4906f8c8af9419c2A015DA9EDc50Ca7973F9a4e7",
"0xf4c2bCA3Fc42fb1e8A2501c0D90b82a85D8f4A53",
"0xEc1fec800E31D5D81A274B22Ec2E62815b011F53",
"0xA2fc4ed579C29F3f1e9d519D5b1a82696C5BB9Ec",
"0xD1eE93fFEb5Aca53a09B5Bbafe66281370EfA4eb",
"0x2F60bbE5c708957C13cc1c67d30eB8D22E180B58",
"0xAc84C2baD9298d731834BFf8af2517491fF1f9F6",
"0x64894Df1Be98166E9c1128848232CB95C60eE6fB",
"0xF0a6F399410c8B4f554DF997b56eC80581146d17",
"0x04E97d160db7c3778AbEF722c382F3f99D651b9b",
"0xb7e6eeBc5D20c32D1E97e0CA748E48E59d8010c6",
"0x193d14F3DFf46705ADE9B56A5Ab14b7A0979fe86",
"0x7675F2A95bE2f17126b51dcD9f1fcC1087014bE2",
"0xAA8a771cC7fF3d549a6E2019a2124cC9F146490b",
"0x58Ad4856C7561C6CdB924b1286E64Ab350b7A3a8",
"0x61D5f834C3DF52E67a82d992B082914a8dBaC417",
"0x1764a7381Be7940f14d85bF47812d750EBd9d015",
"0xeb7C1Ac40650cf6527Bf232fCe90829e7FF7527C",
"0xBF24D26B534A19E4F09c0B698b2CBffF820a452E",
"0x472F0160d4D99e53d09D0158c3F0C61AeC65a3aE",
"0x0454C1bE4F5254c96cB124A75A6247a553BB58e1",
"0x69d519472C9b3E5d050fD5Bb6A153CAc880Bb9bD",
"0xe0306eD73ecbcA5750eC99BCB422a3d45E8F9Be5",
"0x003A1436a9A22B2017990d842d82924BDF48D1e7",
"0xc69eafC309084695652d3d56B4FFeB31e9643Fa4",
"0x46fad3738f89CaC7444Cd50c90589f140EDfDEE6",
"0x361D6628c914eeE6739eC40D11845b7685D31f7b",
"0x84327cb726d0b63c7c46A713e16Ad7B736439eFC",
"0xd84F6e9F97c700857139F66E193E4c25A8b959bB",
"0x87A8b8995676dbAfc7cFe340bCF7d1641561aE0D",
"0x3ba390197e38A27Edf98fE6a9027e0388Ffef041",
"0xDbFdFF155324fB23F5a0bA64c17580A66c9963D4",
"0x52270C61975cd434A214459B22B7e7FE272E09f0",
"0x0e05Fc644943Aae89DD3FEc282C3f86431A7D090",
"0x6BEa09D715E9b89e2A083937eA957C1E6AF6d3cf",
"0xd9cf68615ca5c498bF78952926F8ecf3a9199C2b",
"0x041FA6a56Cc203b210dbAD9eE45DC08C1F88Aba3",
"0x5203Ac1B065EE50A5e0F3272C6205d99A833790b",
"0x6A31b91aD87538b898e69B8b230955c4f76c166a",
"0x32FCD9F84395cE699844EC50eAFFA223b8E7F5FE",
"0xe51f418e1277544CfF57E98192252f53eF2BfE60",
"0x48E031E660afe19261D0711cc2390668D54091B5",
"0x37883F513225cDd045883Db04D7FC587AEF183bC",
"0x54d9158596d4913972E591df1943E4a93894a9CB",
"0x202e53e88aA60190857F30DE4395D3d9614791e0",
"0xB58Ab05E25Fb6Acad7e2FdCEECD6d20C77De0eE1",
"0x58B7090Bea9ecC9421199e41066feAf8068eb7C9",
"0x2f1B7a012cCdaF908cE6c6d7C5e35eC955D480C9",
"0x6aD3D97d5Ad1fc7A1a13aaa620C8A86E5eA09643",
"0xDA1f47f8442041853C5eA39a9f7B97449a7e6ded",
"0x98659144150f82F4f912136619592DAeB6847B30"
  ];